<template>
  <div>
    <VGMSearchBar id="VGMSearchBar" @edit="edit" @search="searchList" :multiple-selection="multipleSelection" :type="search_completionstatus"></VGMSearchBar>
    <VGMTable
        :type="search_completionstatus"
        :curr-page="page"
        :page-size="pageSize"
        :total-page="totalPage"
        :total-count="totalCount"
        :table-header="tableHeaders"
        :table-data="tableData"
        :data-loading="dataLoading"
        @fromChild="getSelection"
        @refresh="getList"
        @edit="edit"
        @copy="edit"
        @nextPage="nextPage"
        @pageSizeChange="pageSizeChange"
        ref="ShManifestTable">
    </VGMTable>
  </div>
</template>

<script>
import mixin from '@/mixin/vgm/vgmTable'
import ManifestSearchBar from "@/views/ShanghaiManifest/components/ManifestSearchBar.vue";

export default {
  components: {ManifestSearchBar},
  mixins: [mixin],
  name: "SentTable",
  data() {
    return {
      search_completionstatus: 2, //搜索的舱单状态
    }
  },
}
</script>

<style lang="stylus" scoped>

</style>
